import {del, get, post, put} from './base'
import qs from "qs";
//-------------------- 菜单管理
//获取菜单
export function getMenu(pageNum,name){
    return get('/v3/menus',{pageNum,name})
}
//添加菜单
export function postMenu(data){
    return post('/v3/menu',qs.stringify(data))
}
//添加二级菜单
export function postSubMenu(data){
    return post('/v3/zmenu',qs.stringify(data))
}
//修改菜单
export function putMenu(data){
    return put('/v3/menu',qs.stringify(data))
}
//删除菜单
export function delMenu(id){
    return del('/api/admin/manageMenus/'+id)
}
//菜单上下移
export function putMenuUp(id){
    return put('/api/admin/manageMenuMove/'+id +'/up' )
}
export function putMenuDown(id){
    return put('/api/admin/manageMenuMove/'+id +'/down' )
}

//--------------------角色管理

export function getRules(pageNum,name){
    return get('/v3/roles',{pageNum,name})
}
//查看角色成员
export function getRulesMember(id){
    return get('/v3/role/' + id)
}
export function getRuleMenu(){
    return get('/v3/menus',{'pageNum':1,'pageSize':9999})
}
//角色菜单权限
export function postRuleMenu(id,data){
    return post('/v3/menu/' + id,qs.stringify(data))
}
//新增角色
export function postRule(data){
    return post('/v3/role',qs.stringify(data))
}
//修改角色
export function putRule(id,data){
    return put('/v3/role/' +id ,qs.stringify(data))
}
//删除角色
export  function delRule(id){
    return del('/v3/role/' + id)
}

//-------------------------客户账户管理
export function  getUserList(pageNum,status,name){
    return get('/v3/users',{pageNum,status,name})
}
export function getUserRules(){
    return get('/v3/roles',{'pageNum':1,'pageSize':9999})
}
export function putUserSwitch(id,data){
    return put('/v3/user/status/' +id,qs.stringify(data))
}
export function postUsers(data){
    return post('/v3/user',qs.stringify(data))
}
export function putUsers(id,data){
    return put('/v3/user/' + id,qs.stringify(data))
}
